<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.fiscal_year_bn : roomRent.fiscal_year }}</b-td>
                                <b-th style="width:20%">{{ $t('org_pro.organization') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.org_bn : roomRent.org }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.office_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.office_bn : roomRent.office }}</b-td>
                                <b-th style="width:20%">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.office_name_bn : roomRent.office_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.guest_house') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.guest_house_bn : roomRent.guest_house }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.room_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.room_type_bn : roomRent.room_type }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id !== 9">
                                <b-th style="width:20%">{{ $t('elearning_config.own_org') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.own_org_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.govt') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.govt_price) }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id == 9">
                                <b-th style="width:20%">{{ $t('elearning_config.own_org1') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.own_org_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.govt1') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.govt_price) }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id !== 9">
                                <b-th style="width:20%">{{ $t('elearning_config.private') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.private_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.domestic_foreign_consultants') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.domestic_foreign_consultants_price) }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id == 9">
                                <b-th style="width:20%">{{ $t('elearning_config.non_govt') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.private_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.domestic_foreign_consultants') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.domestic_foreign_consultants_price) }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id == 9 && roomRent.office_type_id !== 118">
                                <b-th style="width:20%">{{ $t('elearning_config.own_org2') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.nars_grade10_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.non_govt1') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.non_govt_price) }}</b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id == 9">
                                <b-th style="width:20%" v-if="roomRent.office_type_id !== 118">{{ $t('elearning_config.farmer_rent') }}</b-th>
                                <b-td style="width:30%" v-if="roomRent.office_type_id !== 118">{{ $n(roomRent.farmer_price) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.remarks_bn : roomRent.remarks }}</b-td>
                                <b-th style="width:20%" v-if="roomRent.office_type_id == 118"></b-th>
                                <b-td style="width:30%" v-if="roomRent.office_type_id == 118"></b-td>
                            </b-tr>
                            <b-tr v-if="roomRent.org_id !== 9">
                                <b-th style="width:20%">{{ $t('elearning_config.vat_tax') }}</b-th>
                                <b-td style="width:30%">{{ $n(roomRent.vat_tax) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomRent.remarks_bn : roomRent.remarks }}</b-td>
                            </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.roomRent = this.item
  },
  data () {
    return {
      roomRent: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
